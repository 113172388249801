const Star = () => (
	<svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="M7 0.40564L8.98319 4.67602L13.6574 5.24252L10.2089 8.44826L11.1145 13.0688L7 10.7796L2.8855 13.0688L3.79114 8.44826L0.342604 5.24252L5.01681 4.67602L7 0.40564Z"
		/>
	</svg>
)

export default Star
