import clsx from 'clsx'
import Star from 'src/icons/Star'

type Props = {
	rating: number
	className?: string
}

export const ReviewStars = ({ rating, className = '' }: Props) => {
	const roundedRating = Math.round(rating)

	if (roundedRating < 0 || roundedRating > 5) return null

	return (
		<div className={clsx('flex items-center', className)}>
			{[...Array(roundedRating)].map((_, index) => (
				<span key={`Filled star ${index} `} className="text-yellow-400 inline-block mr-[1px]">
					<Star />
				</span>
			))}
			{[...Array(5 - roundedRating)].map((_, index) => (
				<span key={`Unfilled stars ${index}`} className="text-gray-900 inline-block mr-[1px]">
					<Star />
				</span>
			))}
		</div>
	)
}
